<template>
  <div class="sidebar-left">
    <div class="sidebar">

      <!-- Logged In User Profile Sidebar -->
      <!--      <user-profile-sidebar-->
      <!--        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"-->
      <!--        :profile-user-data="profileUserData"-->
      <!--        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"-->
      <!--      />-->

      <!-- Sidebar Content -->
      <div
        class="sidebar-content"
        :class="{'show': mqShallShowLeftSidebar}"
      >

        <!-- Sidebar close icon -->
        <span
          class="sidebar-close-icon"
          style="top: 3px"
        >
          <b-button
            class="btn-icon"
            pill
            variant="flat-secondary"
          >
            <feather-icon
              icon="XIcon"
              size="20"
              @click="$emit('update:mq-shall-show-left-sidebar', false)"
            />
          </b-button>
        </span>

        <!-- Header -->
        <div
          class="chat-fixed-search pr-4 pr-sm-2"
        >
          <div class="d-flex align-items-center w-100">
            <!--            <div class="sidebar-profile-toggle">-->
            <!--              <b-avatar-->
            <!--                size="42"-->
            <!--                class="cursor-pointer badge-minimal avatar-border-2"-->
            <!--                :src="profileUserMinimalData.avatar"-->
            <!--                variant="transparent"-->
            <!--                badge-->
            <!--                badge-variant="success"-->
            <!--                @click.native="$emit('show-user-profile')"-->
            <!--              />-->
            <!--            </div>-->
            <!-- Search -->
            <b-input-group class="input-group-merge w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                placeholder="Поиск..."
                @input="!searchQuery ? search() : ''"
                @keydown.enter="search()"
              />
            </b-input-group>
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <div class="chat-user-list-wrapper list-group scroll-area overflow-auto">

          <!-- Chats Title -->
          <h4 class="chat-list-title">
            Чаты
          </h4>

          <!-- Chats -->
          <ul
            :key="chatsList"
            class="chat-users-list chat-list media-list"
          >
            <chat-contact
              v-for="chat in chats"
              :key="chat.id"
              :contact="chat"
              tag="li"
              :class="{'active': activeChatContactId === chat.id}"
              is-chat-contact
              @click="$emit('open-chat', chat)"
            />

            <infinite-loading
              ref="infiniteLoader"
              @infinite="infiniteHandler"
            >
              <div slot="spinner">
                <div
                  v-for="(skel, key) in 5"
                  :key="key"
                  class="px-1 pt-1"
                >
                  <div class="w-100 d-flex align-items-center">
                    <skeletor
                      class="mr-50 rounded-circle"
                      size="50"
                    />
                    <div>
                      <skeletor
                        width="150px"
                        height="20px"
                      />
                      <skeletor
                        class="mt-50"
                        width="200px"
                        height="15px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div slot="no-more">
                <p class="mt-1 mb-1">
                  Чатов нет
                </p>
              </div>
              <div slot="no-results">
                <p class="mt-1 mb-1">
                  Больше нет чатов
                </p>
              </div>
            </infinite-loading>
          </ul>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  // BAvatar,
  BInputGroup, BInputGroupPrepend, BFormInput, BButton,
} from 'bootstrap-vue'
import InfiniteLoading from 'vue-infinite-loading'
import ChatContact from './ChatContact.vue'
// import UserProfileSidebar from '@/components/chat/UserProfileSidebar.vue'

export default {
  components: {
    // BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    InfiniteLoading,
    BButton,

    // SFC
    ChatContact,
    // UserProfileSidebar,
  },
  props: {
    chats: {
      type: Array,
      required: true,
    },
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      default: null,
    },
    profileUserMinimalData: {
      type: Object,
      default: null,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    searchQuery: '',
    chatsList: 0,
  }),
  methods: {
    search() {
      this.$emit('search', this.searchQuery)
    },
    infiniteHandler($state) {
      this.$emit('infiniteHandler', $state)
    },
    reloadList() {
      this.chatsList += 1
      // this.$refs.infiniteLoader.stateChanger.reset()
    },
  },
}
</script>
