<template>
  <chat-component chats-url="/chats" />
</template>

<script>
import ChatComponent from '@/components/chat/Chat.vue'

export default {
  name: 'Chat',
  components: {
    ChatComponent,
  },
}
</script>

<style lang="scss" scoped>

</style>
