<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <div class="d-flex flex-fill align-items-center">
      <b-avatar
        size="33"
        :src="avatar"
        class="badge-minimal"
      />
      <div class="chat-info flex-grow-1">
        <h5
          class="mb-0 no-off-lines-1 font-weight-bold font-small-4"
          style="max-width: 193px; margin-bottom: 3px"
          :title="contact.name"
        >
          {{ contact.name || 'Администратор' }}
        </h5>
        <p
          v-if="contact.lead_reply"
          class="text-info font-small-3 mb-0"
        >
          Заявка №{{ contact.lead_reply.lead_id }}
        </p>
        <div
          v-if="contact.lastMessage && contact.lastMessage.sender"
          class="card-text no-off-lines-2 font-small-3"
        >
          <span
            v-if="contact.lastMessage.type !== 'room'"
            :title="contact.lastMessage.text"
          >
            {{ contact.lastMessage.sender.id === user.id ? 'Вы: ' : '' }}
            {{ contact.lastMessage.text }}
          </span>
          <div
            v-else
            class="d-flex align-center"
          >
            <span
              v-if="contact.lastMessage.sender.id === user.id"
              class="mr-50"
            >
              Вы:
            </span>
            <feather-icon
              icon="VideoIcon"
              class="mr-50"
              style="margin-top: 2px"
            />
            <span>Комната</span>
          </div>
        </div>
        <p
          v-else
          class="card-text text-truncate"
        >
          Нет сообщений
        </p>
      </div>
    </div>
    <div
      v-if="isChatContact && contact.lastMessage"
      class="chat-meta text-nowrap align-self-center"
    >
      <small
        v-if="!isUnread"
        class="float-right mb-25 chat-time"
      >
        {{ contact.lastMessage.created | timeIfToday }}
      </small>
      <b-badge
        v-else
        pill
        variant="primary"
        class="float-right"
      >
        {{ contact.unread_number }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import { mapGetters } from 'vuex'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    contact: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    chatContacts() {
      return this.contact.contacts
    },
    avatar() {
      return this.chatContacts.filter(contact => contact.avatar)[0]?.avatar ?? ''
    },
    isUnread() {
      return this.contact.unread_number > 0
    },
  },
  setup() {
    return { formatDateToMonthShort }
  },
}
</script>

<style lang="scss" scoped>

</style>
