<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="content-area-wrapper chat-application">
      <chat-left-sidebar
        ref="sidebar"
        :chats="chats"
        :active-chat-contact-id="selectedChat ? selectedChat.id : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="user"
        :profile-user-minimal-data="user"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="() => shallShowUserProfileSidebar = true"
        @open-chat="openChat"
        @infiniteHandler="infiniteHandler"
        @search="searchForChats"
      />
      <chat-area
        :selected-chat="selectedChat"
        @chatClosed="selectedChat = null"
        @onShowSidebar="startConversation"
      />
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { $themeBreakpoints } from '@themeConfig'
import ChatLeftSidebar from '@/components/chat/ChatLeftSidebar.vue'
import ChatArea from '@/components/chat/ChatArea.vue'

const sound = require('@/assets/audio/notification.wav')

export default {
  name: 'Chat',
  components: {
    ChatArea,

    // BSV
    BCard,

    // SFC
    ChatLeftSidebar,
  },
  props: {
    chatsUrl: {
      type: String,
      default: '/chats',
    },
  },
  data: () => ({
    chats: [],
    searchQuery: '',
    limit: 8,
    selectedChat: null,
    mqShallShowLeftSidebar: false,
    shallShowUserProfileSidebar: false,
    abortController: null,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    chatId() {
      return this.$route.query.chat_id
    },
  },
  beforeDestroy() {
    this.unsubFromChats()
  },
  async mounted() {
    const userId = this.user.id
    await this.listenForChats({
      userId,
      callback: ({ chatId }) => {
        this.updateChatsList(chatId)
      },
    })
    if (this.chatId) {
      const chat = await this.getChat(this.chatId)
      await this.openChat(chat)
    }
  },
  methods: {
    ...mapActions({
      listenForChats: 'websockets/listenForChats',
      unsubFromChats: 'websockets/unsubscribeFromChats',
    }),
    infiniteHandler($state) {
      if (this.abortController) this.abortController.abort()
      this.abortController = new AbortController()

      this.$chatsHttp.get(this.chatsUrl, {
        signal: this.abortController.signal,
        params: {
          name: this.searchQuery,
          limit: this.limit,
          offset: this.chats.length,
        },
      }).then(async chats => {
        if (chats.length) {
          this.chats = [...this.chats, ...chats]
          if (chats.length < this.limit) $state.complete()
          else $state.loaded()
        } else {
          $state.complete()
        }
      }).catch(() => {})
    },
    async updateChatsList(chatId) {
      const updatedChat = await this.getChat(chatId)
      if (!this.selectedChat || (this.selectedChat && this.selectedChat.id !== updatedChat.id)) updatedChat.new = true

      const isChatInList = this.chats.find(chat => chat.id === updatedChat.id)
      if (isChatInList) {
        this.chats = this.chats.map(chat => {
          let cht = { ...chat }
          if (cht.id === updatedChat.id) cht = { ...updatedChat }
          return cht
        })
      } else {
        this.chats = [updatedChat, ...this.chats]
      }
      this.sortChatsByUpdatedAt()

      if (!document.hasFocus()) this.notify()
    },
    notify() {
      const audio = new Audio(sound)
      audio.play()
    },
    async getChat(chatId) {
      const chat = await this.$chatsHttp.get(`/chats/${chatId}`)
      return chat
    },
    sortChatsByUpdatedAt() {
      const key = 'updated'

      this.chats = this.chats.sort((a, b) => {
        const keyA = new Date(a[key])
        const keyB = new Date(b[key])
        if (keyA < keyB) return 1
        if (keyA > keyB) return -1
        return 0
      })
    },
    startConversation() {
      if (this.$store.state.app.windowWidth < $themeBreakpoints.lg) {
        this.mqShallShowLeftSidebar = true
      }
    },
    async openChat(chat) {
      this.selectedChat = chat
      this.selectedChat.new = false
      this.selectedChat.unread_number = 0
      this.mqShallShowLeftSidebar = false
    },
    searchForChats(query) {
      this.searchQuery = query
      this.chats = []
      this.$refs.sidebar.reloadList()
      this.selectedChat = null
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
